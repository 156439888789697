import React, { useState, useContext, useEffect } from "react";
import GlobalStateContext from "../context";
import ClientWebService from "../classes/ClientWebService";
import { _notification } from "../utils";
import { Icon } from "antd";



const FollowButton = (props) => {

    const [state, dispatch] = useContext(GlobalStateContext);
    const {user} = state
    const { id,showCount , stream} = props
    const [fLoading, setFLoading] = useState(false);
    const [followers, setFollowers] = useState([]);
    const [canFollow, setCanFollow] = useState(false);


    const getFollowers = async () => {
        const cwebservice = new ClientWebService();
        await cwebservice.followers(id)
            .then(res => res.data)
            .then(_followers => setFollowers(_followers))
            .finally(_ => setFLoading(false));
    }



    useEffect(() => {
        getFollowers();
    }, [])

    useEffect(() => {

        if (!!state.user) {
            const alreadyAFollower = followers.map(ff => ff.follower === state.user._id);
            setCanFollow(alreadyAFollower.indexOf(true) === -1)
        }

    }, [followers])

    const onFollowClick = (e) => {
        setFLoading(true);
        const cwebservice = new ClientWebService();
        const payload = {streamId:stream?.id || stream?._id}
        cwebservice.follow(id,payload)
            .catch(error => _notification.error("Operation Failed"))
            .finally(_ => {
                getFollowers();
                setFLoading(false)
            }
            )
            ;

    }

    return (
        <>
     

                <div  hidden={user._id == id} className=" btn-group pull-right">
                   {
                       fLoading ?
                        <Icon type="loading"/>    
                       :
                   <> 
                   <button
                    hidden={!showCount}
                       
                        className=" btn btn-primary"
                    >
                        {(followers.length).toLocaleString()}
                    </button>
                    <button
                        className=" btn btn-primary"
                        disabled={!state.user}
                        
                        onClick={onFollowClick} >
                        { state.user && !canFollow ? " Unfollow" : " Follow" }
                    </button>
                       </>
                   }
                   
                </div>
      
        </>
    );
}


export default FollowButton;

FollowButton.defaultProps={
    showCount:true
}