import React, { useEffect, useRef, useState } from 'react';
import './AudioVisualizerHtml.css';
import userImage from '../../../../../assets/images/user3.png';

const AudioVisualizerHtml = ({ mediaStream, label }) => {
  const [audioData, setAudioData] = useState([]);
  const audioRef = useRef();
  const circleRef = useRef();

  useEffect(() => {
    let audioSource;
    if (mediaStream) {

      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        requestAnimationFrame(draw);
        analyser.getByteFrequencyData(dataArray);
        setAudioData([...dataArray]);
      };

       audioSource = audioContext.createMediaStreamSource(mediaStream);
      audioSource.connect(analyser);
      draw();
    }

    return () => {
      if(audioSource){
        audioSource.disconnect();

      }
    };
  }, [mediaStream]);

  useEffect(() => {
    const circleElement = circleRef.current;
    const updateCircleSize = () => {
      const maxRadius = 40;
      const minRadius = 10;
      const maxAmplitude = 256;
      const amplitude = Math.max(...audioData);
      const radius = ((amplitude / maxAmplitude) * (maxRadius - minRadius)) + minRadius;
      circleElement.setAttribute('r', radius);
    };

    updateCircleSize();
    window.addEventListener('resize', updateCircleSize);
    return () => {
      window.removeEventListener('resize', updateCircleSize);
    };
  }, [audioData]);

  return (
    <div className="audio-visualizer">
      <svg className="circular-visualizer" viewBox="0 0 100 100">
        <circle ref={circleRef} className="outer-circle" cx="50" cy="50" r="40" />
        <image xlinkHref={userImage} x="30" y="30" width="40" height="40" />
        <text x="50" y="75" textAnchor="middle" className="label" fill="#fff">
          <tspan style={{ fontSize: '0.3rem' }}>{label}</tspan>
        </text>
      </svg>
    </div>
  );
};

export default AudioVisualizerHtml;
