import { Avatar, Button, Card, Icon, message } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useContext, useEffect, useState } from 'react';
import DasbboardWebservice from '../classes/DashboardWebservice';
import LoadingCards from './LoadingCards';
import calendarImage from '../assets/images/calendar_circle.png'
import uploadImage from '../assets/images/cloud-computing.png'
import webacmImage from '../assets/images/video-camera.png'
import videoCall from '../assets/images/meeting.png'
import { NewStreamButton } from '../pages/protected/streams/components/NewStreamButton';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { protectedRoutesPrefix } from '../routers/routes';
import { canIUse, url, canUserCreateStream, remainingStreams } from '../utils';
import GlobalStateContext from '../context';
import { STREAM_SOURCE_ENCODER, STREAM_SOURCE_STUDIO } from '../constants';




// type Props = {
//     onClick: Function,
//     description: String,
//     title: String,
//     image: String | Object,
//     children: ReactDOM,
//     disable: Boolean,
//     selected: Boolean,
//     className?:string

// }

export function FeatureCard(props) {

    const clickableCllass = props.disable ? "inactive" : props.onClick ? " clickable" : "";

    return <>
        {/* <Card onClick={!props.disable && props.onClick} style={{ width: "auto" }} className={`feature-card  ${clickableCllass}  ${props.selected && 'selected'}`} actions={[]} >

            <Meta avatar={<Avatar size={!isMobile ? 100 : 60} src={props.image || ""} />}
                title={props.title || "Title"}
                description={props.description || "Description"} />
            {props.children}

        </Card> */}

        <div 
        className={` ${props.selected ? 'border-2' : 'border'} ${props.className} ${props.disable ? "cursor-not-allowed opacity-50" :''}
        w-96 bg-white  flex flex-column m-2 rounded-lg  divide-y divide-slate-400/25 
         border-blue-500 cursor-pointer transition-all duration-200 transform 
         hover:shadow-lg hover:-translate-y-1`}
            onClick={!props.disable ? props.onClick : ()=>{}}
        >
            <div className='flex flex-row w-full  h-full'>
                <div className='bg-gray-200 my-auto mx-auto rounded-full w-3/12'>
                    <img className='h-full w-full' src={props.image || ""} />
                </div>
                <div className='p-3 text-left w-8/12 md:h-full '>
                    <h3 className='text-lg text-left mt-3 text-slate-700'> {props.title || "Title"}</h3>
                    <div className='text-slate-500'>{props.description || "Description"}</div>
                </div>
            </div>
            {props.children && <div className='h-10 mx-auto bottom-0  w-full '>{props.children}</div>
}
        </div>
    </>
}


export default function DashboardActions() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const history = useHistory();
    const [state, dispatch] = useContext(GlobalStateContext)
    const { features_accessible, app, streams } = state;

    const [canCreate, setCanCreate] = useState();
    const [canRTMP, setCanRTMP] = useState();
    const [canUpload, setCanUpload] = useState();
    const [canInstantStream, setCanInstantStream] = useState();
    const [canUseStudio, setCanUseStudio] = useState();

    function fetchStats() {
        const wbs = new DasbboardWebservice();
        setLoading(true);
        wbs.fetchDasboardData().then(data => {
            setData(data.data);
        }).finally(e => {
            setLoading(false);
        }).catch(err => {
            message.error("Unable to fetch dashboard data. " + err.message);
        });
    }

    useEffect(() => {
        setCanCreate(canUserCreateStream(streams.length, features_accessible));
        setCanInstantStream(canIUse('primary_studio', features_accessible))
        setCanRTMP(canIUse('custom_rtmp_input', features_accessible))
        setCanUpload(canIUse('file', features_accessible))
        setCanUseStudio(canIUse('webcam', features_accessible))
        // setRemainder(remainingStreams(streams.length, features_accessible));
    }, [streams, features_accessible])
    useEffect(() => {
        // child = child || children
    }, [])

    function buildStatCards() {
        const [events, followers, revenue, views] = [data.events, data.followers, data.revenue, data.views]
        return <div className='flex flex-wrap space-x-4 justify-start'>
            <FeatureCard 
            //  disable={!(canInstantStream)} 
             description={
                <div className=''>
                    Go live instantly with any connected device or software.
                </div>
            } title={"Instant Stream"} image={webacmImage} >

                <div className='flex h-full divide-x divide-white rounded-b-lg'>
                    {/* <div className='instant-stream-actions '> */}

                    {/* <Button className=' instant-stream-actions-button' block disabled={!canIUse('primary_studio', features_accessible)} type='default' onClick={_ => canIUse('primary_studio', features_accessible) && history.push(`/instant?s=${STREAM_SOURCE_STUDIO}`)} >From Studio</Button>
                        <Button className='instant-stream-actions-button'block  disabled={!canIUse('custom_rtmp_input', features_accessible)} type='default' onClick={_ => canIUse('custom_rtmp_input', features_accessible) && history.push(`/instant?s=${STREAM_SOURCE_ENCODER}`)} >From Encoder</Button>
                     */}

                    <div className='rounded-bl-lg w-1/2   bg-gray-100 text-black focus:border-none'>
                        <button
                            // disabled={!(canInstantStream && canUseStudio)}
                            className={`disabled:cursor-not-allowed  disabled:opacity-50 cursor-pointer hover:bg-gray-300 square w-full h-full `}
                            onClick={_ =>  history.push(`/instant?s=${STREAM_SOURCE_STUDIO}`)}
                        >From Studio</button>
                    </div>
                    <div className=' rounded-br-lg w-1/2   bg-gray-100 text-black focus:border-none'>
                        <button
                        
                            // disabled={!(canInstantStream && canRTMP)}
                            className={` disabled:cursor-not-allowed   hover:bg-gray-300 disabled:opacity-50 square w-full  h-full rounded-none rounded-br-lg `}
                            onClick={_ => history.push(`/instant?s=${STREAM_SOURCE_ENCODER}`)}
                        >From Encoder</button>
                    </div>

                    {/* </div> */}
                </div>
            </FeatureCard>
            <FeatureCard 
                // disable={!(canCreate && canRTMP)} 
                description={
                <div className=''>
                    Create or schedule an event. Go live right after or later.
                </div>
            } title={"Create Event"}
                image={calendarImage}
            >
                <div className='flex h-full divide-x rounded-b-lg'>
                    {/* <NewStreamButton type='default' >
                        Create Event
                    </NewStreamButton> */}
                    <div className='rounded-b-lg w-full bg-gray-100 text-black focus:border-none'>
                        <button
                            // disabled={!(canCreate && canRTMP)}
                            className={`square w-full h-full disabled:cursor-not-allowed  hover:bg-gray-300 disabled:opacity-50 cursor-pointer`}
                            onClick={_ =>  history.push(url("stream/create", true))}
                        > Create Event</button>
                    </div>
                </div>


            </FeatureCard>
            <FeatureCard
            //  disable={!(canCreate && canUpload)} 
                description={
                <div className=''>
                    Upload pre-recorded video of an event.
                </div>
            } title={"Upload Video"}
                image={uploadImage}
            >
                <div className=''>
                    {/* <NewStreamButton type='default' url={`${protectedRoutesPrefix}/stream/upload`} >Upload </NewStreamButton> */}
                </div>
                <div className='flex h-full divide-x rounded-b-lg'>

                    <div className='rounded-b-lg w-full bg-gray-100 text-black focus:border-none'>
                        <button
                            // disabled={!(canCreate && canUpload)}
                            className={`square w-full h-full disabled:cursor-not-allowed  hover:bg-gray-300  disabled:opacity-50 cursor-pointer "}`}
                            onClick={_ =>  history.push(url(`${protectedRoutesPrefix}/stream/upload`, false))}
                        > Upload </button>
                    </div>
                </div>

            </FeatureCard>
            {/* <FeatureCard description={
                "Start a meeting, share links for participants to join "
            } title="Start an Instant Meeting" image={videoCall} >
                <Button type='dark' onClick={_=> history.push(`${protectedRoutesPrefix}/meet`) } >Start Meeting</Button>
            </FeatureCard> */}

        </div>
        // return data.map(dt => <StatCard/>)
    };

    useEffect(() => {
        fetchStats()
    }, [])


    return (
        <div>
            {
                loading ? <LoadingCards number={5} alignment="row" /> :
                    buildStatCards()
            }
        </div>
    )
}



