import React, { useContext, useState } from 'react'
import GlobalStateContext from '../context'
import { withRouter } from 'react-router-dom';
import { message, Button, Modal, Alert } from 'antd';
import PaymentWebService from '../classes/PaymentWebService';
import { PAYMENT_FLUTTERWAVE, PAYMENT_PAYSTACK } from '../constants';
import LocalMoneyValue from './LocalMoneyValue';
import { FlutterwavePayment, Paystack } from './protected/Subscribe'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import AuthService from '../classes/AuthService';
import { LOGIN } from '../store/actions/types';
import { _notification } from '../utils';




const VToken = ({ token, onOkay, onClose }) => {
    return (
        <div className="flex justify-center items-center min-h-screen ">
            <div className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="bg-blue-500  p-4 text-white">
                    <h2 className="text-3xl font-bold text-center text-white">Ticket Code</h2>
                </div>
                <div className="p-6">
                    <p className="text-gray-700 text-center text-sm">
                        The code below is your ticket. Keep it safe and private.
                    </p>
                    <div className="mt-4">
                        <div className="bg-gray-100 border border-dashed border-gray-400 py-2 px-4 rounded text-center">
                            <span className="text-xl font-semibold tracking-wider text-gray-900">
                                {token}
                            </span>
                        </div>
                    </div>
                    <p className="text-center text-xs text-gray-500 mt-2">
                        *Your payment is valid for this video. You may have to pay for other videos that require payment*.
                    </p>
                    <div className="mt-6 text-center">
                        <button onClick={onOkay} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">
                            Apply Ticket
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
};


export function PayTicketFlow(props) {
    const { location, amount, entity, type = 'ppv', redirect_url, showButton, showPopup, payment_method } = props;
    const txWebservice = new PaymentWebService();
    const authWebservice = new AuthService();
    const [state, dispatch] = useContext(GlobalStateContext);

    const { currency, user, xrate, userLocationData } = state;
    const [txId, setTxId] = useState("")
    const [_loading, setLoading] = useState(false);
    const [showInputModal, setShowInputModal] = useState(false);
    const [showTicket, setShowTicket] = useState(false);
    const [showPayment, setShowPayment] = useState(false)
    const [userId, setUserId] = useState(false)
    const [userTicket, setUserTicket] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [vuser, setVuser] = useState({})
    const [enterToken, setEnterToken] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(payment_method || (currency.code === "GHS" ? PAYMENT_PAYSTACK : PAYMENT_FLUTTERWAVE))
    const [modalVisibility, setModalVisibility] = useState(showPopup || true)

    const payable = amount //@TODO Add coupon call, tax, etc



    const onPaymentSuccess = (resp) => {
        //Submit payment to api
        setLoading(false);
        setShowPayment(false);
        closeModal();
        setShowTicket(true)

        return true;
    }

    const applyToken = (data = null) => {
        authWebservice.login({
            email: data?.email || vuser?.email,
            password: data?.vtoken || vuser?.vtoken
        }).then(result => {
            dispatch({
                type: LOGIN,
                payload: result.data
            });
            setTimeout(_ => { window.location.reload() }, 1000);
        }).catch(error => {
            if (error.request?.status == 401) {
                setErrorMsg("Invalid ticket code provided")
            } else {

            }
        }).finally(_ => {
            setEnterToken(false)
            setLoading(false)
        })

    }

    const onPaymentClose = (resp) => {
        // txWebservice.deleteTransaction(txId)
        //     .then(resp => console.log(resp));

        setLoading(false);
        setShowPayment(false);
        return true;
    }




    const closeModal = () => {
        setModalVisibility(false);
    }
    const closeInputModal = () => {
        setShowInputModal(false);
        setLoading(false)
    }


    const confirmOrPay = () => {



        if (!userId) {

            setErrorMsg("Provide phone number")
            return
        }
        setLoading(true);
        setErrorMsg(null)

        if (enterToken) {
            console.log(userTicket)

            applyToken({
                email: `${userId}@viblica.com`,
                vtoken: userTicket
            });

            return;

        }

        const service = new PaymentWebService();
        service.confirm({
            user_id: userId,
            entity: entity.id || entity._id,
            amount: entity.ppv_value
        }).then(res => {
            console.log(res)
            if (res.data == false) {
                onInitializeTx()
            } else {
                setEnterToken(true)
            }
        }).catch(reason => {
            onInitializeTx()

        }).finally(() => {
            setLoading(false);
        })

    }
    const onInitializeTx = () => {
        setLoading(true);
        setShowInputModal(false);
        console.log("offfff", entity)

        const dxPayload = {
            entity_id: entity.id || entity._id,
            payment_method: paymentMethod,
            currency_code: currency.code,
            type,
            unauth_id: userId
        }

        txWebservice.pay(dxPayload)
            .then(resp => resp.data)
            .then(tx => {
                console.log(tx)
                setTxId(tx?.reference);
                setVuser(tx?.user);
                setShowPayment(true)
                return;
            })
            .catch(err => {
                setLoading(false);
                console.log(err)
                if (err?.response?.status !== 401) {

                    message.error("Failed. Please try later")
                }
            })
    }
    const onClickPay = () => {
        setLoading(true)
        setErrorMsg(null)
        setShowTicket(false)
        setEnterToken(false)

        if (!user) {
            //show form 
            console.log("xxxx")
            setShowInputModal(true)
            setLoading(false)
        } else {
            onInitializeTx()
        }


    }





    return (
        <>
            <Button
                loading={_loading}
                hidden={!showButton}
                onClick={onClickPay} >
                Enter Ticket or Pay&nbsp;
                {<LocalMoneyValue

                    value={payable} showSymbol={true}
                />} </Button>



            {
                paymentMethod === PAYMENT_FLUTTERWAVE &&

                <FlutterwavePayment
                    currency={currency.code}
                    visible={showPayment}
                    user={user || { phone: userId, email: `${userId}@viblica.com` }}
                    amount={(parseFloat(payable) * parseFloat(xrate)).toFixed(2)}
                    txref={txId}
                    onClose={onPaymentClose}
                    onSuccess={onPaymentSuccess}
                    title={`Onetime Payment `}
                    description={`Onetime Payment for ${entity.title || entity.name} `}
                    redirect_url={redirect_url}
                    meta={{ entity }}
                    country={userLocationData.country_code}
                />
            }

            {
                paymentMethod === PAYMENT_PAYSTACK &&
                <Paystack
                    currency={currency.code}
                    visible={showPayment}
                    user={user || { phone: userId, email: `${userId}@viblica.com` }}
                    amount={(parseFloat(payable) * parseFloat(xrate) * 100).toFixed(2)}
                    txref={txId}
                    onClose={onPaymentClose}
                    onSuccess={onPaymentSuccess}
                    title={`Onetime Payment `}
                    description={`Onetime Payment for ${entity.title || entity.name} `}
                    redirect_url={redirect_url}
                    meta={{ entity }}
                    country={userLocationData.country_code}
                />

            }

            {
                showInputModal && (
                    <Modal

                        visible={showInputModal}
                        onOk={onInitializeTx}
                        onCancel={closeInputModal}
                        footer={false}
                    >
                        <form className='mt-3' onSubmit={confirmOrPay}>


                            {!enterToken &&
                                <>
                                    <div className='m-2 text-xl'>Provide phone number</div>
                                    <div className='m-2'>

                                        <PhoneInput
                                            country={"gh"}
                                            onChange={_ => setUserId(_)}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                                autoFocus: true
                                            }}
                                        />
                                        <div className='m-1'>If you have already made payment for this event you'll be asked to enter ticket code </div>

                                    </div>
                                </>

                            }


                            {enterToken && <div className='m-2'>
                                <>
                                    <div className='m-2 text-xl'>Enter Ticket Code</div>
                                    <div className='mb-1'>You've already paid for this event.  Enter your ticket code below</div>
                                    <div className='react-tel-input'>
                                        <input required placeholder='XXXXXX' className=' form-control ' onChange={_ => setUserTicket(_.target.value)} />

                                    </div>
                                </>
                            </div>}

                            {errorMsg && <Alert type="error" message={errorMsg} banner />}

                            <Button loading={_loading} className='m-2 ' block type='success' onClick={confirmOrPay}> Proceed </Button>
                        </form>
                    </Modal>
                )
            }
            {
                showTicket && (

                    <VToken token={vuser?.vtoken} onOkay={applyToken} />



                )
            }



        </>
    )
}

export default withRouter(PayTicketFlow);